
<template>
  <div class="flex">
    <div :style="{ 'border-color': borderColor }" :class="computeClass" class="relative w-full border justify-start items-center inline-flex">
      <div class="w-full">
        <input
          :id="id"
          v-model="model"
          :type="type"
          :name="name"
          :maxlength="maxlength"
          :placeholder="label"
          :readonly="readonly"
          :disabled="disabled"
          :autocomplete="autocomplete"
          @keydown.enter="$emit('enter')"
          @keydown="$emit('keydown', $event)"
          :class="{'!rounded-[0.5rem]': !isShowButton}"
          class="w-full text-[#3C4549] text-sm font-poppins"
          @focus="labelFocused = true"
          @blur="labelFocused = false; $emit('blur');"
          style="border:none;"
        />
      </div>
      <slot name="error_message"></slot>
      <div class="absolute top-0 right-0">
        <slot name="right_opt"></slot>
        <slot name="right_btn"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InputButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array],
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
      required: true,
    },
    showIconRight: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    maxlength: {
      type: [Number, String],
      default: 524288,
    },
    readonly: {
      type: [Boolean, String, Number],
      default: false,
    },
    disabled: {
      type: [Boolean, String, Number],
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    customClass: {
      type: String,
      default: 'h-[56px]'
    },
    errorType: {
      type: String,
      default: ''
    },
    isShowButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelFocused: false
    }
  },
  computed: {
    name() {
      return this.label.toLowerCase()
    },
    borderColor() {

      if(this.errorType === 'danger') {
        return '#BE185D !important'
      }
      if(this.errorType === 'warning') {
        return '#F4B740 !important'
      }
      if(this.errorType === 'green') {
        return '#3ABA6D !important'
      }

      return this.labelFocused ?  '#2766E4 !important' : '#D2D5DF !important'
    },
    computeClass() {
      const errorTypeColors = {
        danger: 'bg-[#FFFCFC]',
        warning: 'bg-[#FFFEF9]',
        green: 'bg-[#FBFFFA]',
      };

      const defaultColor = this.labelFocused ? 'border-[#2766E4]' : 'border-[#D2D5DF]';
      const selectedColor = errorTypeColors[this.errorType] || defaultColor;

      return selectedColor + ' ' + (this.isShowButton ? 'rounded-tl-lg rounded-bl-lg' : 'rounded-lg') + ' ' + this.customClass + (this.disabled ? ' select-none cursor-not-allowed' : '');
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>



